<template>
  <div class="recommendFriendList">
    <!-- 筛选条件 -->
    <el-row class="mb-30px">
      <el-col :span="5" class="flex items-center">
        <div class="flex-none mr-8px">邀请记录ID:</div>
        <el-input v-model="queryParams.recommendFriendId" clearable placeholder="请输入邀请记录ID" />
      </el-col>
      <el-col :span="5" class="flex items-center ml-20px">
        <div class="flex-none mr-8px">类型:</div>
        <el-select v-model="queryParams.rewardResource" placeholder="请选择赠送类型">
          <el-option v-for="item in rewardResourceOptions" :key="item.value" :label="item.key" :value="item.value" />
        </el-select>
      </el-col>
      <el-col :span="5" class="flex items-center ml-20px">
        <div class="flex-none mr-8px">会员:</div>
        <el-input v-model="queryParams.idNamePhone" clearable placeholder="请输入手机号/名称/ID" />
      </el-col>
      <el-col :span="5" class="flex items-center ml-20px">
        <div class="flex-none mr-8px">时间:</div>
        <el-date-picker
          v-model="createTime"
          :default-time="[new Date('1 00:00:00'), new Date('1 23:59:59')]"
          end-placeholder="结束日期"
          start-placeholder="开始日期"
          type="daterange"
          value-format="YYYY-MM-DD HH:mm:ss"
          @change="timeChange"
        />
      </el-col>
      <el-col :span="2" class="ml-20px">
        <el-button @click="getTabList" type="primary">查 询</el-button>
      </el-col>
    </el-row>
    <!-- table表格 -->
    <el-table stripe :data="data.table" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column type="index" width="50" />
      <el-table-column prop="recommendFriendId" label="邀请记录ID" show-overflow-tooltip />
      <el-table-column prop="rewardResource" label="类型" />
      <el-table-column prop="memberType" label="角色" />
      <el-table-column prop="memberId" label="会员ID" show-overflow-tooltip />
      <el-table-column prop="memberPhone" label="手机号" />
      <el-table-column prop="memberName" label="会员名称" />
      <el-table-column prop="reward" label="获得奖励" show-overflow-tooltip />
      <el-table-column prop="creationTime" label="时间" :formatter="formatCreationTime" width="160" />
    </el-table>
    <!-- <Pagination :total="data.total" layout="prev, pager, next, sizes, total" v-model:page="queryParams.page" v-model:limit="queryParams.pageSize" @pagination="pageChange" /> -->
    <!-- 分页 -->
    <el-pagination 
      class="fenye" 
      @current-change="pageChange" 
      :current-page="queryParams.page" 
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;" next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;" 
      background
      layout="total, prev, pager, next, jumper" 
      :total="data.total"
    >
    </el-pagination>
  </div>
</template>

<script setup name="RecommendFriendList">
  import { ref, reactive, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { FullTimeFormat } from '@/utils/common'
  import { getRecommendFriendList } from "@/http/bannermanagement";
  const route = useRoute()
  const router = useRouter()
  
  let bannerManagementId = route.query.bannerManagementId
  // 查询条件
  let queryParams = reactive({
    idNamePhone: '',
    rewardResource: '',
    recommendFriendId: '',
    friendPhone: '',
    startTime: '',
    endTime: '',
    bannerManagementId: bannerManagementId,
    page: 1,
    pageSize: 10
  })
  // 类型
  let rewardResourceOptions = reactive([
    { key: '注册奖励', value: '1' },
    { key: '消费奖励', value: '2' }
  ])
  let createTime = ref('')
  function timeChange(value) {
    if(value) {
      queryParams.startTime = value[0]
      queryParams.endTime = value[1]
    } else {
      queryParams.startTime = ''
      queryParams.endTime = ''
    }
  }
  let tableLoading = ref(false);
  // table 数据
  let data = reactive({
    table: [],
    pages: 0,
    total: 0
  })
  // 列表查询
  function getTabList() {
    tableLoading.value = true
    getRecommendFriendList(queryParams).then(res => {
      tableLoading.value = false
      data.total = res.totalCount
      data.table = res.items
    }).catch(() => {
      tableLoading.value = false
    })
  }
  // 分页
  function pageChange(index) {
    queryParams.page = index;
    getTabList(queryParams);
  }
  function formatCreationTime(row) {
    return FullTimeFormat(row.creationTime)
  }
  onMounted(() => {
    if (bannerManagementId == null || bannerManagementId.trim().length == 0) {
      router.push({ name: 'banner-management' })
    }
    getTabList({
      Page: 1,
      PageSize: 10,
      bannerManagementId: bannerManagementId
    })
  });

</script>

<style lang="scss" scoped>
.fenye {
  margin-top: 20px;
}
</style>
